@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  width: calc(100% - 50px);
  margin: auto;
  padding: 40px 0 10px;
  .row {
    background-color: variable.$secondaryColor;
    border-radius: 10px;
    .col {
      flex: 1;
      &.image {
        margin-bottom: 15px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      &.titles {
        padding: 15px;
        font-size: 34px;
        line-height: 28px;
        .title {
          color: variable.$primaryColor;
          margin: 0 0 5px;
          font-size: inherit;
          line-height: inherit;
          text-align: left;
        }
        .subtitle {
          margin: 0;
          color: variable.$primaryColor;
          line-height: inherit;
          font-size: 66%;
          font-family: variable.$primaryFontBold;
          padding-left: 2%;
        }
      }
      &.quote {
        font-family: variable.$primaryFontBold;
        font-weight: 600;
        font-size: variable.$size20;
        color: variable.$whiteColor;
        line-height: 26px;
        padding-inline: 40px 30px;
        padding-bottom: 30px;
        position: relative;
        border-radius: 0 0 10px 10px;
        p {
          &::before,
          &::after {
            content: "";
            position: absolute;
            left: -10px;
            width: 40px;
            height: 33px;
            mask-image: url("/static/images/icons/right-quote.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            background-color: currentColor;
            color: variable.$primaryColor;
            transform: rotate(180deg);
          }
          &::after {
            position: static;
            display: inline-block;
            width: 22px;
            height: 18px;
            transform: scale(-1, 1) translateY(5px) rotate(180deg);
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@include mixin.mediaSmallTabletSize() {
  .wrapper {
    .row {
      display: flex;
      .col {
        &.image {
          min-width: 200px;
          max-width: 200px;
          flex: 1;
          margin-bottom: 0;
          margin-right: 15px;
          border-radius: 10px 0 0 10px;
          img {
            height: 100%;
          }
        }
        &.content {
          margin: auto 0;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    max-width: 1000px;
    .row {
      .col {
        &.image {
          position: relative;
          img {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            object-fit: cover;
            object-position: top center;
          }
        }
        &.titles {
          padding: 30px;
          font-size: variable.$size36;
          padding-right: 0;
          font-size: 44px;
          .subtitle {
            padding-left: 0;
            max-width: 300px;
            font-size: 56%;
            padding-top: 10px;
          }
        }
        &.quote {
          padding: 30px;
          padding-left: 0;
          font-size: variable.$size22;
          p {
            &::before {
              left: -10px;
              top: -10px;
            }
          }
        }
        &.content {
          display: flex;
        }
      }
    }
  }
}
